import React, { useState, useEffect } from "react";
import "./App.css";

type Event = {
  type: "touch" | "release";
  time: number;
  data: {
    index: number;
  };
};

const isWithinHour = (hour: number) => (event: Event) => {
  const eventDate = new Date(event.time);
  const eventHour = eventDate.getHours();
  return hour === eventHour;
};

const TouchCountRow: React.FC<{
  events: Event[];
  label: string;
  isAll?: boolean;
}> = ({ events, label, isAll }) => {
  return (
    <tr>
      <td className="header">{label}</td>
      <td className="all">{events.length}</td>
      <td className={isAll ? "all" : ""}>
        {events.filter(isWithinHour(6)).length}
      </td>
      <td className={isAll ? "all" : ""}>
        {events.filter(isWithinHour(7)).length}
      </td>
      <td className={isAll ? "all" : ""}>
        {events.filter(isWithinHour(8)).length}
      </td>
      <td className={isAll ? "all" : ""}>
        {events.filter(isWithinHour(9)).length}
      </td>
      <td className={isAll ? "all" : ""}>
        {events.filter(isWithinHour(10)).length}
      </td>
      <td className={isAll ? "all" : ""}>
        {events.filter(isWithinHour(11)).length}
      </td>
      <td className={isAll ? "all" : ""}>
        {events.filter(isWithinHour(12)).length}
      </td>
      <td className={isAll ? "all" : ""}>
        {events.filter(isWithinHour(13)).length}
      </td>
      <td className={isAll ? "all" : ""}>
        {events.filter(isWithinHour(14)).length}
      </td>
      <td className={isAll ? "all" : ""}>
        {events.filter(isWithinHour(15)).length}
      </td>
      <td className={isAll ? "all" : ""}>
        {events.filter(isWithinHour(16)).length}
      </td>
      <td className={isAll ? "all" : ""}>
        {events.filter(isWithinHour(17)).length}
      </td>
      <td className={isAll ? "all" : ""}>
        {events.filter(isWithinHour(18)).length}
      </td>
      <td className={isAll ? "all" : ""}>
        {events.filter(isWithinHour(19)).length}
      </td>
      <td className={isAll ? "all" : ""}>
        {events.filter(isWithinHour(20)).length}
      </td>
      <td className={isAll ? "all" : ""}>
        {events.filter(isWithinHour(21)).length}
      </td>
      <td className={isAll ? "all" : ""}>
        {events.filter(isWithinHour(22)).length}
      </td>
    </tr>
  );
};

const EventsDisplay: React.FC<{ events: Event[] }> = ({ events }) => {
  return (
    <div>
      <table className="hours">
        <thead>
          <tr>
            <th className="split-cell header">
              <div>
                <span className="bottom">area</span>
                <span className="top">time</span>
                <div className="line"></div>
              </div>
            </th>
            <th className="header">all</th>
            <th className="header">6:00</th>
            <th className="header">7:00</th>
            <th className="header">8:00</th>
            <th className="header">9:00</th>
            <th className="header">10:00</th>
            <th className="header">11:00</th>
            <th className="header">12:00</th>
            <th className="header">13:00</th>
            <th className="header">14:00</th>
            <th className="header">15:00</th>
            <th className="header">16:00</th>
            <th className="header">17:00</th>
            <th className="header">18:00</th>
            <th className="header">19:00</th>
            <th className="header">20:00</th>
            <th className="header">21:00</th>
            <th className="header">22:00</th>
          </tr>
        </thead>
        <tbody>
          <TouchCountRow events={events} isAll label="all" />
          <TouchCountRow
            events={events.filter(
              (event) => event.data && event.data.index === 0
            )}
            label="0"
          />
          <TouchCountRow
            events={events.filter(
              (event) => event.data && event.data.index === 1
            )}
            label="1"
          />
          <TouchCountRow
            events={events.filter(
              (event) => event.data && event.data.index === 2
            )}
            label="2"
          />
          <TouchCountRow
            events={events.filter(
              (event) => event.data && event.data.index === 3
            )}
            label="3"
          />
          <TouchCountRow
            events={events.filter(
              (event) => event.data && event.data.index === 4
            )}
            label="4"
          />
          <TouchCountRow
            events={events.filter(
              (event) => event.data && event.data.index === 5
            )}
            label="5"
          />
          <TouchCountRow
            events={events.filter(
              (event) => event.data && event.data.index === 6
            )}
            label="6"
          />
          <TouchCountRow
            events={events.filter(
              (event) => event.data && event.data.index === 7
            )}
            label="7"
          />
          <TouchCountRow
            events={events.filter(
              (event) => event.data && event.data.index === 8
            )}
            label="8"
          />
          <TouchCountRow
            events={events.filter(
              (event) => event.data && event.data.index === 9
            )}
            label="9"
          />
          <TouchCountRow
            events={events.filter(
              (event) => event.data && event.data.index === 10
            )}
            label="10"
          />
          <TouchCountRow
            events={events.filter(
              (event) => event.data && event.data.index === 11
            )}
            label="11"
          />
        </tbody>
      </table>
    </div>
  );
};

function App() {
  const [from, setFrom] = useState(new Date());
  const [to, setTo] = useState(new Date());
  const [events, setEvents] = useState<Event[]>([]);
  const [orderedTouchEvents, setOrderedTouchEvents] = useState<Event[]>([]);

  useEffect(() => {
    const fromString = from.toLocaleDateString("NL-nl", {
      day: "numeric",
      month: "2-digit",
      year: "numeric",
    });
    const toString = to.toLocaleDateString("NL-nl", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    fetch(
      `https://touchmeplease-d4eb9-default-rtdb.europe-west1.firebasedatabase.app/log/${fromString}.json`
    )
      .then((result) => result.json())
      .then((result: { [key: string]: Event }) => {
        if (!result) {
          setEvents([]);
          return;
        }
        const resultArray = Object.keys(result).map((key) => result[key]);
        setEvents(resultArray);
      });
  }, [from, to]);

  useEffect(() => {
    setOrderedTouchEvents(
      events
        .filter((event) => event.type === "touch")
        .sort((a, b) => a.time - b.time)
    );
  }, [events]);

  return (
    <div>
      <div className="controls">
        <label>
          date{" "}
          <input
            type="date"
            name="from"
            value={from.toISOString().split("T")[0]}
            onChange={(e) =>
              e.target.valueAsDate && setFrom(e.target.valueAsDate)
            }
          />
        </label>{" "}
        {/* <label>
        to{" "}
        <input
        type="date"
        name="to"
        value={to.toDateString()}
        onChange={(e) => e.target.valueAsDate && setTo(e.target.valueAsDate)}
        />
      </label> */}
      </div>
      <div>
        <EventsDisplay events={orderedTouchEvents} />
      </div>
    </div>
  );
}

export default App;
